import React from 'react';
import useMedia, { mediaBreakpoints } from '../../hooks/use-media';
import { STORE_URLS } from '../../utils/constants';
import Logo from '../logo';
import StoreBtn from '../store-btn';
import * as style from './index.module.scss';
import FooterList from './list';
import { LinkList } from './models';

const { sm, md } = mediaBreakpoints;

export default function Footer() {
  const logoSize = useMedia([md, sm], [250, 200], 150);

  const lists: LinkList[] = [
    {
      title: 'Products',
      items: [
        { label: 'Buy', href: '/buy' },
        { label: 'Refinance', href: '/refinance' },
        { label: 'Home Equity Loan', href: '/loan' },
        { label: 'Home Equity Line', href: '/line' },
        { label: 'Find Homes', href: '/find-home' }
      ]
    },
    {
      title: 'About',
      items: [
        { label: 'Company', href: '/about' },
        { label: 'Careers', href: '/careers' },
        { label: 'News', href: '/soon#news' },
        { label: 'Learn', href: '/learn' },
        { label: 'Privacy Policy', href: '/privacy-policy' }
      ]
    },
    {
      title: 'Support',
      items: [
        { label: 'Customer Care', href: '/care' },
        { label: '1 888 372 5588', href: 'tel:+18883725588' },
        { label: 'getstarted@nuborrow.com', href: 'mailto:getstarted@nuborrow.com' }
      ]
    },
    {
      title: 'Connect',
      items: [
        { label: 'Facebook', href: 'https://facebook.com/nuborrow' },
        { label: 'Twitter', href: 'https://twitter.com/nuborrow_' },
        { label: 'YouTube', href: 'https://www.youtube.com/channel/UCazjJ1FHQpE6EP9HIc6JCOA' },
        { label: 'Instagram', href: 'https://instagram.com/nuborrow' },
        { label: 'LinkedIn', href: 'https://ca.linkedin.com/company/nuborrow' }
      ]
    }
  ];

  return (
    <footer className={style.footer + ' w-100'}>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-3">
            <Logo width={logoSize} />
          </div>
        </div>
        <div className={style.links + ' row'}>
          {lists.map((list) => (
            <div className="col-12 col-sm-6 col-lg-3" key={list.title}>
              <FooterList title={list.title} items={list.items} />
            </div>
          ))}
        </div>
        <div className={style.appDownload + ' row'}>
          <h3>Download the Nuborrow App</h3>
          <div
            className={
              style.buttons +
              ' d-flex flex-column flex-sm-row align-items-stretch align-items-sm-center'
            }
          >
            <StoreBtn store={'apple'} url={STORE_URLS.iosAppStore} />
            <StoreBtn store={'google'} url={STORE_URLS.playStore} />
          </div>
        </div>
        <div className="row">
          <span className={style.copyright + ' text-gray text-center text-md-start small'}>
            Copyright © {new Date().getFullYear()} NuBorrow Inc. <br className="d-md-none" />
            License Number: 13115. <br className="d-md-none" />
            Designed by{' '}
            <a href="https://fantechlabs.io" target="_blank">
              Fantech Labs
            </a>
            .
          </span>
        </div>
      </div>
    </footer>
  );
}
