import { Link } from 'gatsby';
import React from 'react';
import * as style from './list.module.scss';
import { LinkItem } from './models';

export default function FooterList(props: { title: string; items: LinkItem[] }) {
  return (
    <ul className={style.list + ' list-unstyled'}>
      <li>{props.title}</li>
      {props.items.map((item) => (
        <li key={item.href}>
          {item.href.startsWith('/') ? (
            <Link to={item.href}>{item.label}</Link>
          ) : (
            <a href={item.href}>{item.label}</a>
          )}
        </li>
      ))}
    </ul>
  );
}
