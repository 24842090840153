import { SiApple } from '@react-icons/all-files/si/SiApple';
import { SiGoogleplay } from '@react-icons/all-files/si/SiGoogleplay';
import React from 'react';
import { StylableProp } from '../../utils/stylable-prop';
import * as style from './index.module.scss';

interface StoreBtnProps {
  store: 'apple' | 'google';
  url: string;
  darkMode?: boolean;
}

export default function StoreBtn({ store, darkMode, url, className }: StylableProp<StoreBtnProps>) {
  const text = store === 'apple' ? 'Available on the' : 'Get it on';
  const storeTitle = store === 'apple' ? 'App Store' : 'Google Play';
  const icon = store === 'apple' ? <SiApple /> : <SiGoogleplay />;
  const mode = darkMode ? style.dark : style.light;

  return (
    <a
      href={url}
      target="_blank"
      className={`${style.button} d-flex align-items-center ${mode} ${className}`}
    >
      <span className={style.icon}>{icon}</span>
      <span className={style.text + ' d-flex flex-column align-left'}>
        <span>{text}</span>
        <strong>{storeTitle}</strong>
      </span>
    </a>
  );
}
