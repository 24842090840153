import { motion } from 'framer-motion';
import React, { forwardRef, PropsWithChildren } from 'react';
import { StylableProp } from '../../utils/stylable-prop';
import * as style from './index.module.scss';

export const SectionDescription = forwardRef<
  HTMLParagraphElement,
  StylableProp<PropsWithChildren<{ desc?: string }>>
>((props, ref) => {
  return (
    <p ref={ref} className={`${style.desc} ${props.className ?? ''}`}>
      {props.children ?? props.desc}
    </p>
  );
});

export const MotionSectionDescription = motion(SectionDescription);
