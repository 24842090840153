import { motion } from 'framer-motion';
import React, { forwardRef, PropsWithChildren } from 'react';
import { StylableProp } from '../../utils/stylable-prop';
import * as style from './index.module.scss';

export const SectionSubheading = forwardRef<
  HTMLHeadingElement,
  StylableProp<PropsWithChildren<{ text?: string; compact?: boolean }>>
>((props, ref) => {
  return (
    <h4
      ref={ref}
      className={`${style.subheading} ${props.compact ? style.compact : ''} ${props.className}`}
    >
      {props.children ?? props.text}
    </h4>
  );
});

export const MotionSectionSubheading = motion(SectionSubheading);
