import { Link } from 'gatsby';
import React from 'react';
import logo from '../../images/nuborrowLogo.svg';
import { StylableProp } from '../../utils/stylable-prop';
import * as style from './index.module.scss';

export default function Logo(props: StylableProp<{ width: number }>) {
  return (
    <div className={`${style.logo} ${props.className}`} style={{ width: props.width }}>
      <Link className="navbar-brand d-block" to={'/'}>
        <figure>
          <img src={logo} alt="site logo" />
        </figure>
      </Link>
    </div>
  );
}
