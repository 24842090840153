import { Link } from 'gatsby';
import React from 'react';
import { StylableProp } from '../../utils/stylable-prop';
import * as style from './index.module.scss';

interface NavItem {
  label: string;
  href: string;
}

export default function Navbar(props: StylableProp<unknown>) {
  const navItems: NavItem[] = [
    {
      label: 'Buy',
      href: '/buy'
    },
    {
      label: 'Refinance',
      href: '/refinance'
    },
    {
      label: 'Home Equity Line',
      href: '/line'
    },
    {
      label: 'Home Equity Loan',
      href: '/loan'
    },
    // {
    //   label: 'Find a Home',
    //   href: '/find-home'
    // }
    // {
    //   label: 'Tools',
    //   href: '/soon#tools'
    // },
    // {
    //   label: 'Free Credit Score',
    //   href: '/soon#score'
    // }
  ];

  return (
    <nav className={`${style.navbar} ${props.className}`}>
      <ul className={style.navList + ' d-flex align-items-center justify-content-center w-100'}>
        {navItems.map((item) => (
          <li key={item.label} className={style.navItem + ' nav-item position-relative'}>
            <Link
              className={style.navLink + ' nav-link text-capitalize'}
              activeClassName={style.active}
              to={item.href}
            >
              {item.label}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
}
