import Nuborrow from '../images/nuborrow_logo.svg';
import CMLSFinancial from '../images/optimized/logos_cmls_financial.svg';
import TDBank from '../images/optimized/logos_td_bank.svg';

const BANK_LOGO_MAP: Record<string, string> = {
  Nuborrow: Nuborrow,
  'CMLS Financial': CMLSFinancial,
  'TD Bank': TDBank
};

export class RootState {
  productRates: Record<ProductType, Product | null>;

  private get productRatesEndpoint() {
    return `https://nu2morrow.com/crm/apis/products/rates`;
    // return '/data/rates.json';
  }

  constructor() {
    this.productRates = {
      Buying: null,
      'Equity Line': null,
      'Equity Loan': null,
      Refinancing: null
    };
  }

  async fetchProductRates() {
    const response = await fetch(this.productRatesEndpoint);

    if (response.status !== 200) {
      const error = new Error(`Unable to fetch Product Rates from API`);
      console.error(error);
      console.error(await response.json());

      throw error;
    }

    const json: ProductRatesAPIResponse = await response.json();

    for (const product of json.rates) {
      if (this.productRates.hasOwnProperty(product.name)) {
        this.productRates[product.name] = {
          name: product.name,
          description: product.description,
          effectiveDate: product.effective_date,
          banks: []
        };

        for (const bank of product.banks) {
          this.productRates[product.name]?.banks.push({
            name: bank.bank_name,
            logo: bank.bank_logo,
            rate: bank.bank_rate,
            variable_rate: bank.bank_rate_variable,
            ltv: bank.ltv,
            term: bank.term,
            description: bank.description
          });
        }

        this.productRates[product.name]?.banks.sort((a, b) => a.variable_rate - b.variable_rate);
      }
    }

    return this.productRates;
  }

  findLowestRateForProduct(productType: ProductType) {
    const product = this.productRates[productType];

    if (!product || !product.banks?.length) {
      return null;
    }

    // the first bank is always the lowest rate since we sort the API response
    return product.banks[0];
  }

  findLowestRatesForProduct(productType: ProductType, count: number) {
    const product = this.productRates[productType];

    if (!product || !product.banks?.length) {
      return [];
    }

    // the first bank is always the lowest rate since we sort the API response
    return product.banks.slice(0, count);
  }

  getLogoForBank(name: string) {
    return BANK_LOGO_MAP[name] ?? null;
  }
}

export type ProductType = 'Buying' | 'Refinancing' | 'Equity Line' | 'Equity Loan';

export interface Product {
  name: ProductType;
  description: string;
  banks: Bank[];
  effectiveDate: Date;
}

export interface Bank {
  name: string;
  logo: string;
  rate: number;
  variable_rate: number;
  ltv?: number;
  term: string;
  description: string;
}

export interface ProductRatesAPIResponse {
  status: number;
  rates: {
    name: ProductType;
    description: string;
    effective_date: Date;
    banks: {
      bank_name: string;
      bank_logo: string;
      bank_rate: number;
      bank_rate_variable: number;
      ltv?: number;
      term: string;
      description: string;
    }[];
  }[];
}
