import React, { useEffect, useRef, useState } from 'react';
import useMedia, { mediaBreakpoints } from '../../hooks/use-media';
import Logo from '../logo';
import Navbar from '../navbar';
import RoundBtn from '../round-btn';
import * as style from './index.module.scss';

const { sm, lg, xl } = mediaBreakpoints;

export default function Header() {
  const headerRef = useRef<HTMLElement>(null);
  const [navbarClass, setNavbarClass] = useState('');
  const logoSize = useMedia([xl, lg, sm], [150, 100, 150], 100);

  const handleHeaderScroll = (e: Event) => {
    if (window.scrollY > 50) {
      headerRef.current?.classList.add(style.shrink);
      setNavbarClass(style.navbar);
    } else {
      headerRef.current?.classList.remove(style.shrink);
      setNavbarClass('');
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleHeaderScroll);
    return () => window.removeEventListener('scroll', handleHeaderScroll);
  });

  return (
    <header
      ref={headerRef}
      id="header"
      className={
        style.header +
        ' d-flex align-items-center px-3 px-sm-4 px-lg-3 px-xl-4 w-100 position-fixed'
      }
    >
      <div
        className={
          style.headerContainer +
          ' col d-flex flex-column align-items-center justify-content-center'
        }
      >
        <div className="main-header-block d-flex align-items-center justify-content-between w-100">
          <div className={style.logoBlock}>
            <Logo width={logoSize} />
          </div>
          <Navbar className={`d-none d-lg-block ${navbarClass}`} />
          <div className={`${style.buttons} d-flex align-items-center justify-content-end`}>
            {/* <RoundBtn className={`${style.defaultBtn} ${style.btn} d-none d-sm-block`}>
              <span className="d-xl-none">Sign in</span>
              <span className="d-none d-xl-inline">Sign in / Sign up</span>
            </RoundBtn> */}
            <RoundBtn
              className={`${style.primaryBtn} ${style.btn}`}
              href="https://apply.nuborrow.com"
            >
              Get Started
            </RoundBtn>
          </div>
        </div>
      </div>
    </header>
  );
}
