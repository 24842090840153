import React, { MouseEvent, PropsWithChildren, useEffect, useRef, useState } from 'react';
import { StylableProp } from '../../utils/stylable-prop';
import * as style from './index.module.scss';
import { HighlightState, PerspectiveCardProps, PerspectiveState } from './model';

interface DefaultState {
  perspectiveState: PerspectiveState;
  highlightState: HighlightState;
}

const defaultState: DefaultState = {
  perspectiveState: {
    transform: 'perspective(1200px)',
    transition: 'transform 0.2s ease-in-out',
    transformStyle: 'initial'
  },
  highlightState: {
    transform: '',
    transition: 'transform 0.2s ease-in-out',
    opacity: 0.05,
    '--aspect-ratio': 1
  }
};

function getDefaultState(state: keyof DefaultState) {
  return defaultState[state];
}

export default function PerspectiveCard(
  props: StylableProp<PropsWithChildren<PerspectiveCardProps>>
) {
  const [perspectiveState, setPerspectiveState] = useState<PerspectiveState>(
    getDefaultState('perspectiveState') as PerspectiveState
  );

  const [highlightState, setHighlightState] = useState<HighlightState>(
    getDefaultState('highlightState') as HighlightState
  );

  const selfRef = useRef<HTMLDivElement>(null);

  const intensity = (props.intensity ?? 100) / 100;

  const translate = 15 * intensity;
  const scale = 1 + (5 / 100) * intensity;
  const angle = 5 * intensity;
  const transformBase = `perspective(1200px)`;

  let hWidth = 0;
  let hHeight = 0;
  let angleX = angle;

  useEffect(() => {
    if (!hWidth) {
      hWidth = (selfRef.current?.offsetWidth ?? 0) / 2;
    }

    if (!hHeight) {
      hHeight = (selfRef.current?.offsetHeight ?? 0) / 2;
    }

    angleX = angle + angle * (hWidth / hHeight);
  });

  const onMouseEnter = () => {
    setPerspectiveState({ ...perspectiveState, ...{ transition: 'none' } });
    setHighlightState({ ...highlightState, ...{ transition: 'none', opacity: 0.2 } });
  };

  const onMouseLeave = () => {
    setPerspectiveState(getDefaultState('perspectiveState') as PerspectiveState);
    setHighlightState(getDefaultState('highlightState') as HighlightState);
  };

  const onMouseMove = (evt: MouseEvent) => {
    if (!hWidth || !hHeight) {
      return;
    }

    const absX = evt.nativeEvent.offsetX;
    const absY = evt.nativeEvent.offsetY;

    const x = absX - hWidth;
    const y = absY - hHeight;

    const rX = x / hWidth;
    const rY = y / hHeight;

    let transform = `${transformBase} `;
    transform += `translate3d(${rX * translate}px, ${rY * translate}px, 0) `;
    transform += `scale3d(${scale}, ${scale}, 1) `;
    transform += `rotateX(${-1 * rY * angle}deg) rotateY(${rX * angleX}deg) `;

    setPerspectiveState({ ...perspectiveState, ...{ transform, transformStyle: 'preserve-3d' } });

    const distance = Math.sqrt(Math.pow(rX * -75, 2) + Math.pow(rY * -75, 2));
    const highlightTranslate = `translate(${rX * -75}%, ${rY * -75}%)`;

    setHighlightState({
      ...highlightState,
      ...{
        transform: highlightTranslate,
        opacity: Math.min(1, Math.max(distance / 100, 1 / 3)) * 0.3,
        '--aspect-ratio': hHeight / hWidth
      }
    });
  };

  return (
    <div
      ref={selfRef}
      className={`${style.wrapper} ${props.className}`}
      style={{ ...perspectiveState, ...props.style }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseMove={onMouseMove}
    >
      {props.children}
      {props.showHighlight ? <div className={style.highlight} style={highlightState}></div> : null}
    </div>
  );
}
