import { navigate } from 'gatsby-link';
import React, {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  PropsWithChildren,
  useState
} from 'react';
import { StylableProp } from '../../utils/stylable-prop';
import * as style from './index.module.scss';
import { RoundBtnProps } from './model';

export default function RoundBtn(props: StylableProp<PropsWithChildren<RoundBtnProps>>) {
  const styles: { [property: string]: string | number } = {};
  const [disabled, setDisabled] = useState(false);

  if (props.height) {
    styles['--btn-line-height'] = props.height;
  }

  const handleClick = async (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>
  ) => {
    if (!props.href) {
      return;
    }

    setDisabled(true);

    if (props.hrefTarget === '_blank') {
      window.open(props.href, '_blank');
    } else {
      if (props.href.startsWith('http')) {
        window.open(props.href, '_top');
      } else {
        await navigate(props.href);
      }
    }

    setDisabled(false);
    e.preventDefault();
  };

  const elProps: ButtonHTMLAttributes<HTMLButtonElement> = {
    className: `${style.button} ${props.isPrimary ? style.primaryBtn : style.defaultBtn} ${
      props.className
    }`,
    style: styles,
    onClick: props.onClick ? props.onClick : (e) => handleClick(e),
    children: props.children
  };

  if (props.href) {
    (elProps as AnchorHTMLAttributes<HTMLAnchorElement>).href = props.href;
    (elProps as AnchorHTMLAttributes<HTMLAnchorElement>).target = props.hrefTarget;
  } else {
    (elProps as ButtonHTMLAttributes<HTMLButtonElement>).type = props.type ?? 'button';
    (elProps as ButtonHTMLAttributes<HTMLButtonElement>).disabled = disabled;
  }

  return props.href ? (
    <a {...(elProps as AnchorHTMLAttributes<HTMLAnchorElement>)} />
  ) : (
    <button {...elProps} />
  );
}
