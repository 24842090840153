import { motion } from 'framer-motion';
import React, { forwardRef, PropsWithChildren } from 'react';
import { StylableProp } from '../../utils/stylable-prop';
import * as style from './index.module.scss';

export const SectionTitle = forwardRef<
  HTMLHeadingElement,
  StylableProp<PropsWithChildren<{ title?: string; compact?: boolean }>>
>((props, ref) => {
  return (
    <h1
      ref={ref}
      className={`${style.title} ${props.compact ? style.compact : ''} ${props.className}`}
    >
      {props.children ?? props.title}
    </h1>
  );
});

export const MotionSectionTitle = motion(SectionTitle);
export default SectionTitle;
