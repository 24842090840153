import { graphql, useStaticQuery } from 'gatsby';
import { getImage, ImageDataLike } from 'gatsby-plugin-image';
import React, { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { parseSrcset } from 'srcset';
import { SiteMetaQuery } from '../../graphql-types';
import { RootState } from '../utils/root-state';
import Footer from './footer';
import Header from './header';
// import styles
import './layout.scss';

export interface LayoutProps {
  pageTitle: string;
  onRootState?: (state: RootState) => void;
}

interface SocialMetaItem {
  title: string;
  description: string;
  image?: string;
}

interface SocialMeta {
  facebook?: SocialMetaItem;
  twitter?: SocialMetaItem;
}

export default function Layout({
  pageTitle,
  children,
  onRootState
}: React.PropsWithChildren<LayoutProps>) {
  const metadata = useStaticQuery<SiteMetaQuery>(graphql`
    query SiteMeta {
      site {
        siteMetadata {
          siteUrl
        }
      }
      allFile(filter: { name: { regex: "/icon$/gi" } }) {
        nodes {
          relativePath
          publicURL
        }
      }
      siteBuildMetadata {
        buildTime
      }
      strapiHeader {
        seo {
          metaTitle
          metaDescription
          keywords
          metaSocial {
            id
            socialNetwork
            title
            description
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 1200
                    height: 630
                    layout: CONSTRAINED
                    placeholder: NONE
                    formats: JPG
                  )
                }
              }
            }
          }
        }
      }
    }
  `);

  const rootStateRef = useRef<RootState>();

  useEffect(() => {
    if (!rootStateRef.current) {
      rootStateRef.current = new RootState();
    }

    rootStateRef.current.fetchProductRates().then((rates) => {
      // console.log(`Parsed Rates: `, rates);

      onRootState?.(rootStateRef.current!);
    });
  }, []);

  const icons: Record<string, string | null | undefined> = {
    'favicon.ico': ''
    // 'icon.svg': '',
    // 'apple-touch-icon.png': ''
  };

  metadata.allFile.nodes?.forEach((n) => {
    if (!icons[n.relativePath]) {
      icons[n.relativePath] = n.publicURL;
    }
  });

  const siteUrl = metadata.site?.siteMetadata?.siteUrl!;
  const buildTime = metadata.siteBuildMetadata?.buildTime;

  const title = `${metadata.strapiHeader?.seo?.metaTitle} ${pageTitle && `| ${pageTitle}`}`;
  const description =
    metadata.strapiHeader?.seo?.metaDescription ??
    'Everything you need in one place to buy, refinance and save more money on your next mortgage move';

  const socials: SocialMeta = {};

  metadata.strapiHeader?.seo?.metaSocial?.forEach((social) => {
    const socialData: SocialMetaItem = {
      title: social?.title ?? title,
      description: social?.description ?? description
    };

    const imgData = getImage(social?.image?.localFile as unknown as ImageDataLike);

    if (imgData?.images.fallback?.srcSet) {
      socialData.image = parseSrcset(imgData.images.fallback?.srcSet)
        .sort((a, b) => b.width! - a.width!)
        .map((s) => s.url)[0];
    }

    switch (social?.socialNetwork) {
      case 'Facebook':
        socials.facebook = socialData;
        break;

      case 'Twitter':
        socials.twitter = socialData;
        break;
    }
  });

  return (
    <div id="wrapper">
      <Helmet>
        <title>{title}</title>

        <meta name="description" content={description} />

        {metadata.strapiHeader?.seo?.keywords && (
          <meta name="keywords" content={metadata.strapiHeader?.seo?.keywords} />
        )}

        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={socials.facebook?.title} />
        <meta property="og:description" content={socials.facebook?.description} />
        <meta property="og:url" content={siteUrl} />
        <meta property="og:site_name" content="Nuborrow" />
        <meta property="og:updated_time" content={buildTime} />
        <meta property="og:image" content={siteUrl + socials.facebook?.image} />

        {icons['favicon.ico'] && <link rel="icon" href="/favicon.ico" sizes="any" />}
      </Helmet>
      <Header />
      <main className={'layout-container'}>{children}</main>
      <Footer />
    </div>
  );
}
